import React, { Component } from "react";
// import logo from "./logo.svg";
import logo from "./logo.png";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            <h1>Go Fetch Labs</h1>
            contact@gofetchlabs.com
          </p>
          <p>
            Creators of{" "}
            <a
              href="https://www.morningcorgi.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              www.morningcorgi.com
            </a>
            {" "}and{" "}
            <a
              href="https://www.morningretriever.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              www.morningretriever.com
            </a>
            !
          </p>
        </header>
      </div>
    );
  }
}

export default App;
